import React from 'react'
import * as styles from './styles.module.scss'

export const Input = React.forwardRef(({ as = 'input', className = '', ...props }, ref) => {
    return React.createElement(as, {
        className: `${styles.input} ${className}`,
        ref,
        ...props
    })
})

export function Button({ as = 'button', className = '', ...props }) {
    return React.createElement(as, {
        className: `${styles.button} ${className}`,
        ...props
    })
}
