import React from 'react'
import * as styles from './styles.module.scss'

export function FormItem({ label, children, formId }) {
    const input = React.Children.only(children)
    const id = `${formId}_${input.props.name}`
    return (
        <>
            <label className={styles.itemLabel} htmlFor={id}>{label}</label>
            {React.cloneElement(input, {
                id,
                className: `${styles.itemContent} ${input.props.className || ''}`
            })}
        </>
    )
}

export function FormSummary({ className = '', ...props }) {
    return (
        <div className={`${className} ${styles.itemContent}`} {...props} />
    )
}

const FormItemType = (<FormItem />).type

export function Form({ className = '', id, children, ...props }) {
    return (
        <form className={`${className} ${styles.root}`} id={id} {...props}>
            {React.Children.map(children, child => {
                if (child.type === FormItemType) {
                    return React.cloneElement(child, {
                        formId: id
                    })
                }
                return child
            })}
        </form>
    )
}
