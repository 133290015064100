import React from "react";
import { ReCaptchaProvider } from "./src/recaptcha";
import "@fontsource/bebas-neue/400.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/700.css"
import "./src/styles/variables.scss";
import "./src/styles/global.scss";
import "./src/styles/utils.scss";

export function wrapRootElement({ element }) {
  return (
    <ReCaptchaProvider
      sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
      size="invisible"
      theme="dark"
    >
      {element}
    </ReCaptchaProvider>
  );
}
