import React from "react";
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

export default function Seo({ title, ldJsonItems = [] }) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    description,
    siteUrl
  } = site.siteMetadata

  const seoTitle = title ? `${title} | ${defaultTitle}` : defaultTitle
  const seoUrl = `${siteUrl}${pathname}`
  const seoImage = `${siteUrl}/site-image.jpg`

  return (
    <Helmet title={seoTitle}>
      <meta name="description" content={description} />

      <meta property="og:url" content={seoUrl} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={seoImage} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={seoImage} />

      {ldJsonItems.map((item, i) => (
        <script key={`ld-json-${i}`} type="application/ld+json">
          {JSON.stringify(item)}
        </script>
      ))}
    </Helmet>
  );
}

const query = graphql`
  query SeoQuery {
    site {
      siteMetadata {
        defaultTitle: title
        description
        siteUrl
      }
    }
  }
`;
