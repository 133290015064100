import React from "react";
import { graphql } from "gatsby";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from 'react-toastify';
import Container from "../components/Container";
import { Button, Input } from '../components/Control'
import Seo from '../components/Seo'
import { useReCaptcha } from "../recaptcha"
import { Form, FormItem, FormSummary } from "../components/Form"
import * as styles from "./kontakt.module.scss";
import 'react-toastify/dist/ReactToastify.css';

export default function ContactPage({ data }) {
  const { contact, siteUrl, title, description } = data.site.siteMetadata;
  
  const reCaptcha = useReCaptcha()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  async function onSubmit(data) {
    try {
      const captchaResponse = await reCaptcha.current.executeAsync()
      const response = await fetch("/api/mails", {
        method: "POST",
        body: JSON.stringify({ ...data, captchaResponse }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error('API Error')
      }
      toast.success('Wiadomość została wysłana!', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    catch (error) {
      toast.error('Wystąpił błąd podczas wysyłania wiadomości', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    finally {
      reCaptcha.current.reset()
    }
  }

  const businessSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    url: siteUrl,
    name: title,
    telephone: contact.phone,
    image: `${siteUrl}/site-image.jpg`,
    logo: `${siteUrl}/logo.svg`,
    email: contact.email,
    description,
    priceRange: '$',
    address: {
      "@type": "PostalAddress",
      streetAddress: `${contact.address.street} ${contact.address.house}`,
      addressLocality: `${contact.address.city}, gm. ${contact.address.commune}`,
      addressRegion: "woj. Śląskie",
      postalCode: contact.address.postalCode,
      addressCountry: "PL"
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 50.49564793456385,
      longitude: 19.572897175823837
    }
  }

  return (
    <>
      <Seo title="Kontakt" ldJsonItems={[businessSchema]} />
      <Container>
        <h1>Kontakt</h1>
        <div className={styles.split}>
          <section>
            <h2>Dane kontaktowe</h2>
            <address>
              <p>{contact.name}</p>
              <dl>
                <dt>NIP</dt>
                <dd>{contact.nip}</dd>
                <dt>REGON</dt>
                <dd>{contact.regon}</dd>
                <dt>BDO</dt>
                <dd>{contact.bdo}</dd>
                <dt>TransID</dt>
                <dd>{contact.transId}</dd>
                <dt>Email</dt>
                <dd>
                  <a href={`mailto:${contact.email}`}>{contact.email}</a>
                </dd>
                <dt>Telefon</dt>
                <dd>
                  <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                </dd>
                <dt>Adres</dt>
                <dd>
                  ul. {contact.address.street} {contact.address.house}
                </dd>
                <dd>
                  {contact.address.postalCode} {contact.address.city} (gm.{" "}
                  {contact.address.commune})
                </dd>
              </dl>
            </address>
          </section>
          <section>
            <h2>Formularz kontaktowy</h2>
            <Form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
              <FormItem label="Email">
                <Input type="email" required {...register("email")} />
              </FormItem>
              <FormItem label="Temat">
                <Input type="text" {...register("subject")} />
              </FormItem>
              <FormItem label="Treść">
                <Input as="textarea" rows={8} required {...register("text")} />
              </FormItem>
              <FormSummary>
                <Button type="submit" disabled={isSubmitting}>
                  Wyślij wiadomość
                </Button>
              </FormSummary>
            </Form>
          </section>
        </div>
      </Container>
      <iframe
        className={styles.gmaps}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2538.0758756383752!2d19.570719216116103!3d50.49554557948149!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4717185ac9663a77%3A0xa942e2a20b8e50c7!2sAdam%20Bia%C5%82y%20Adex!5e0!3m2!1spl!2spl!4v1639307057998!5m2!1spl!2spl"
        width="100%"
        height={450}
        allowFullScreen
        loading="lazy"
        title="Mapa Google z adresem firmy"
      />
      <ToastContainer />
    </>
  );
}

export const query = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        contact {
          name
          nip
          regon
          bdo
          transId
          email
          phone
          address {
            street
            house
            postalCode
            city
            commune
          }
        }
      }
    }
  }
`;
