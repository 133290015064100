import React from "react";
import { navigate } from "gatsby";
import Container from "../components/Container";
import Seo from "../components/Seo";

export default function AboutUsPage() {
  return (
    <>
      <Seo />
      <Container>
        <h1>Strony nie znaleziono</h1>
        <p>
          <button onClick={() => navigate("/")}>
            Przejdż do strony głównej
          </button>
        </p>
      </Container>
    </>
  );
}
