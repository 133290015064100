import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Header from "../Header";
import Footer from '../Footer'
import * as styles from "./styles.module.scss";

export default function Layout({ children }) {
  const { site } = useStaticQuery(query)

  return (
    <>
      <Header
        className={styles.header}
        menuLinks={site.siteMetadata.menuLinks}
      />
      <main>
        {children}
      </main>
      <Footer
        menuLinks={site.siteMetadata.menuLinks}
        contact={site.siteMetadata.contact}
      />
    </>
  );
}

const query = graphql`
  {
    site {
      siteMetadata {
        menuLinks {
          name
          link
        }
        contact {
          name
          email
          phone
          address {
            street
            house
            postalCode
            city
            commune
          }
        }
      }
    }
  }
`
