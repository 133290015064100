import React from "react"
import { Link } from "gatsby"
import Container from "../Container";
import * as styles from "./styles.module.scss"
import logo from "../../images/logo-light.svg";

export default function Header({ menuLinks, className = '' }) {
    return (
        <header className={`${styles.root} ${className}`}>
            <Container className={`${styles.rootInner}`}>
                <Link to="/" className={styles.logo}>
                    <img src={logo} alt="ADEX" />
                </Link>
                <nav>
                    <ul className={styles.menu}>
                        {menuLinks.map(item => (
                            <li key={item.link}>
                                <Link activeClassName={styles.active} to={item.link}>
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </Container>
        </header>
    )
}
