import React from 'react'
import { graphql, navigate } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Banner from '../components/Banner'
import { Button } from '../components/Control'
import Seo from '../components/Seo'
import logo from "../images/logo-light.svg"
import * as styles from './index.module.scss'

export default function IndexPage({ data }) {
  return (
    <>
      <Seo />
      <Banner
        className={styles.bannerContent}
        bkg={<GatsbyImage
          image={getImage(data.mainBanner)}
          alt="ADEX banner background"
        />}
      >
        <img src={logo} className={styles.logo} alt={data.site.siteMetadata.title} />
        <p>{data.site.siteMetadata.subTitle}</p>
        <p>Tel: {data.site.siteMetadata.contact.phone}</p>
        <Button onClick={() => navigate('/kontakt')}>Skontaktuj się z nami!</Button>
      </Banner>
    </>
  )
}

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        subTitle
        contact {
          phone
        }
      }
    }
    mainBanner: file(
      relativePath: {eq: "banner.jpg"}
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
        )
      }
    }
  }  
`
