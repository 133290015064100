import React, { useMemo, useCallback, useState } from "react";
import { graphql } from "gatsby";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Container from "../components/Container";
import Seo from '../components/Seo'

export default function GalleryPage({ data }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const photos = useMemo(() => {
    return data.gallery.edges.map((e, i) => {
      const { width, height, images } = e.node.childImageSharp.gatsbyImageData
      return {
        ...images.fallback,
        width,
        height,
        alt: `ADEX galeria zdjęcie ${i + 1}`
      }
    })
  }, [data])

  return (
    <>
      <Seo title="Galeria" />
      <Container>
        <h1>Galeria</h1>
      </Container>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
}

export const query = graphql`
  query GalleryQuery {
    gallery: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "gallery" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
