import React from 'react'
import Container from '../Container'
import * as styles from './styles.module.scss'

export default function Banner({ bkg, ...props }) {
    return (
        <div className={styles.root}>
            <div className={styles.bkg}>
                {bkg}
            </div>
            <Container {...props} />
        </div>
    )
}
