import React from "react"
import { Link } from "gatsby"
import Container from "../Container"
import * as styles from "./styles.module.scss"

export default function Footer({ menuLinks, contact, className = '' }) {
    return (
        <Container as="footer" className={`${styles.root} ${className}`}>
            <div className={styles.footerGrid}>
                <section>
                    <h3>Na skróty</h3>
                    <nav>
                        <ul>
                            <li key="/">
                                <Link to="/">
                                    Strona główna
                                </Link>
                            </li>
                            {menuLinks.map(item => (
                                <li key={item.link}>
                                    <Link to={item.link}>
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </section>
                <section>
                    <h3>Skontaktuj się z nami</h3>
                    <address>
                        <p>{contact.name}</p>
                        <dl>
                            <dt>Email</dt>
                            <dd><a href={`mailto:${contact.email}`}>{contact.email}</a></dd>
                            <dt>Telefon</dt>
                            <dd><a href={`tel:${contact.phone}`}>{contact.phone}</a></dd>
                            <dt>Adres</dt>
                            <dd>ul. {contact.address.street} {contact.address.house}</dd>
                            <dd>{contact.address.postalCode} {contact.address.city} (gm. {contact.address.commune})</dd>
                        </dl>
                    </address>
                </section>
            </div>
            <div className={styles.footerSub}>
                <p className={styles.inlineSplits}>
                    <span>&copy; {new Date().getFullYear()} {contact.name}</span>
                    <Link to="/sitemap/sitemap-index.xml">Mapa strony</Link>
                </p>
                <p>Strona używa technologii Google reCAPTCHA podczas wysyłania wiadomości przez formularz kontaktowy w celu ochrony przez botami.</p>
            </div>
        </Container>
    )
}
