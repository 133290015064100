import React, { useContext, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const ReCaptchaContext = React.createContext();

export function ReCaptchaProvider({ children, ...props }) {
  const recaptchaRef = useRef()

  return (
    <ReCaptchaContext.Provider value={recaptchaRef}>
      {children}
      <ReCAPTCHA
        ref={recaptchaRef}
        {...props}
      />
    </ReCaptchaContext.Provider>
  )
}

export function useReCaptcha() {
  return useContext(ReCaptchaContext)
}
