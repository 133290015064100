import React from "react";
import Container from "../components/Container";
import Seo from "../components/Seo";

export default function AboutUsPage() {
  return (
    <>
      <Seo title="O nas" />
      <Container>
        <h1>O nas</h1>
        <p>
          Firma ADEX, której założycielem jest Adam Biały powstała w 2012 roku.
          Obecnie jest firmą rodzinną, zatrudniającą wykwalifikowaną kadrę
          kierowców. Nasza flota składa się z nowoczesnych ciągników siodłowych
          marki SCANIA oraz naczep typu chłodnie oraz firanki marki SCHMITZ.
          Firma stale się rozwija i z roku na rok powiększa swój tabor o kolejne
          pojazdy.
        </p>
      </Container>
    </>
  );
}
